import { useMemo, useState, useEffect } from 'react';
import { fetchStock } from "../../utils/fetchApi"
import classes from './stocks.module.css'
import StockItem from './StockItem';
import Loader from '../../components/Loader';
import StocksHeading from './StocksHeading/stocksHeading';
import ReactPaginate from 'react-paginate';
import Popup from './popupFilter';
import { Download } from 'react-feather';
import _ from 'lodash';
import { fetchApi } from '../../utils/fetchApi';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function Stocks(props) {
    let nav = useNavigate();
    let getUser = JSON.parse(localStorage.getItem('USER'));
    const [stocks, setStocks] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchVar, setSearchVar] = useState({});
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const nbPage = {
        10: 10,
        20: 20,
        30: 30,
    }
    const [startAt, setStartAt] = useState(null);
    const [endAt, setEndAt] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isFilterInput, setIsFilterInput] = useState(false);
    const [filter, setFilter] = useState({
        clientCode: {visibility: getUser?.userGroup.code === "superadmin" ? true : false , input: getUser?.userGroup.code === "superadmin" ? null : getUser?.userGroup.code},
        date: { visibility: true, input: null },
        articleCode: { visibility: true, input: null },
        reservation: { visibility: true, input: null },
        preparation: { visibility: true, input: null },
        disponible: { visibility: true, input: null },
        rupture: { visibility: true, input: false },
        stockTotal: { visibility: true, input: null },
    });
    const [currentFilter, setCurrentFilter] = useState(null);
    const [numberStock, setNumberStock] = useState(null);
    const [numberStockTotal, setNumberStockTotal] = useState(null);
    const [modaleOpen,setModaleOpen] = useState(false);
    const [typeModale,setTypeModale] = useState(null);
    const [loadingModale,setLoadingModale] = useState(false);
    const [modaleData,setModaleData] = useState([]);
    const [orderBy,setOrderBy] = useState('desc');
    const [isFilterOrder, setIsFilterOrder] = useState(false);


    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const handleCheck = (e, type) => {
        setIsFilterInput(false)
        let getFilter = { ...filter };
        getFilter[type].visibility = !getFilter[type].visibility;
        setFilter(getFilter);
    }
    const handleClick = async(data, type) => {
        setLoadingModale(true);
        setTypeModale(type)
        let result = await fetchApi(`stti/stock/${type}/${data.id}`,null, 'GET', true);
        if (result){
            setModaleData(result)
            setModaleOpen(true)
        }
    }

    const handleChangeFilter = (e, type) => {
        if (type === "orderBy"){
            setOrderBy(e)
            setIsFilterOrder(true)
        }else{
            setIsFilterOrder(false)
            setIsFilterInput(true)
            let getFilters = { ...filter };
            getFilters[type].input = e;
            setFilter(getFilters);
            setCurrentFilter(_.cloneDeep(getFilters));
        }
    }

    const getStocks = async (pageCurrent) => {
        //TODO DYNAMIC PARENTS VARIABLE
        let arrayParents = [];

        if (localStorage.getItem('AUTH_EMAIL') === 'ccp@gmail.com') {
            arrayParents.push({ "eav_type": "CLIENT", "shellDatas": [{ "identifier": "client_code", "value": "CCP" }] });
        }
        else if (localStorage.getItem('AUTH_EMAIL') === 'cote@gmail.com') {
            arrayParents.push({ "eav_type": "CLIENT", "shellDatas": [{ "identifier": "client_code", "value": "COTE" }] });
        }

        let variables = {
            "itemsPerPage": itemsPerPage,
            "page": pageCurrent + 1 || currentPage + 1,
            "orderBy": orderBy
        };
        for (const [key, value] of Object.entries(filter)) {
            if (value.input){
                variables[key] = value.input
            }
        }


        setLoading(true);

        let stocks = await fetchStock(variables,'GET',variables);

        setNumberStockTotal(stocks.paginationInfo.totalCount);
        setNumberStock((pageCurrent + 1 || currentPage + 1) * itemsPerPage);
        setStocks(stocks?.collection);
        // setItemsPerPage(stocks.paginationInfo.itemsPerPage);
        setTotalCount(stocks.paginationInfo.totalCount);
        setLastPage(stocks.paginationInfo.lastPage);

        setLoading(false);
    }

    // const handleSearch = (e) => {
    //     e?.preventDefault();
    //     setSearchVar({ ...searchVar, "NUM_CMD_CLIENT": e.target.search.value })
    // }

    const listStocks = useMemo(() => {
        return (
            stocks?.map((e, index) => {
                return (
                    <StockItem stock={e} key={`stock-${index}`} state={currentFilter} handleClick={handleClick}/>
                )
            })
        )
    }, [stocks]);

    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        getStocks(selectedPage);
    };
    const handleApply = () => {
        setIsOpen(false)
        let getFilters = {...filter}
        setCurrentFilter(_.cloneDeep(getFilters))
    };

    useEffect(() => {
        if (!getUser.seeStock){
            nav('/')
        }else{
            getStocks();
        }
    }, [searchVar, itemsPerPage]);

    useEffect(() => {
        if (isFilterInput) {
            const timeout = setTimeout(() => {
                getStocks()
            }, 2000)
            return () => clearTimeout(timeout);
        }
    }, [filter, currentFilter]);

    useEffect(() => {
        if (isFilterOrder){
            getStocks()
        }
    }, [orderBy]);

    useEffect(() => {
        props.logout(true, false);
        let getFilters = {...filter};
        setCurrentFilter(_.cloneDeep(getFilters))  
    }, []);

    const resetStates = () => {
        setTypeModale(null)
        setModaleData([]);
        setModaleOpen(false);
    }
    const handleClose = () => {
        resetStates()
    }
    const handleExport = async() => {
        let result = await fetchApi(`stti/stock/export`,null, 'GET', true);
        if (result?.url){
            window.open(`${process.env.REACT_APP_URL}${result.url}`)
        }
    }

    const renderModaleContent = () => {
        switch(typeModale){
            case 'reservation':
                return (
                    <div className={classes.contentModale}>
                        <h3>RESERVATION</h3>
                        <table className={classes.tableStocksModale}>
                            <thead>
                                <tr>
                                    <th>Commande</th>
                                    <th>Code article</th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    modaleData.map((item) => {
                                        return(
                                            <tr>
                                                <td>{item.commande}</td>
                                                <td>{item.articleId}</td>
                                                <td>{item.quantite}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <Button label="Fermer" onClick={() => handleClose()}/>
                    </div>
                )
            case 'rupture':
                return (
                    <div className={classes.contentModale}>
                        <h3>RUPTURE</h3>
                        <table className={classes.tableStocksModale}>
                            <thead>
                                <tr>
                                    <th>Commande</th>
                                    <th>Code article</th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    modaleData.map((item) => {
                                        return(
                                            <tr>
                                                <td>{item.commande}</td>
                                                <td>{item.articleId}</td>
                                                <td>{item.quantite}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <Button label="Fermer" onClick={() => handleClose()}/>
                    </div>
                )
            default:
                return null
        }
    }

    const IS_DEV = false;
    const date = moment(listStocks?.[0]?.props.stock.updatedAt.date).format('DD/MM/YYYY à hh:mm');
    return (
        <div className={classes.root}>
            {isOpen && <Popup
                handleClose={() => { setIsOpen(!isOpen) }}
                handleApply={() => handleApply()}
                state={filter}
                handleCheck={handleCheck}
            />}
            {/* <p class="right">Stocks</p> */}
            {/* <div className={classes.periodSearch}>
                <p className={classes.leftTitle}>Période de recherche</p>
                <div className={classes.inputsDates}>
                    <div className={classes.inputDate}>
                        <p className={classes.dateTitle}>Date de début</p>
                        <input className={classes.inputTypeDate} type="date" />
                    </div>
                    <div className={classes.inputDateBis}>
                        <p className={classes.dateTitle}>Date de fin</p>
                        <input className={classes.inputTypeDate} type="date" min={startAt} />
                    </div>
                </div>
            </div> */}
            {/* <div>
                <div className={classes.filter}>
                    <p className={classes.leftTitleFilter}>Filtres</p>
                    <img className={classes.pictoFilter} src={PictoFilterBlue} onClick={togglePopup}/>
                </div>
                <div className={classes.search}>
                </div>
            </div> */}
            <div className={classes.orders_container}>
                {loading ?
                    <Loader label="chargement en cours" />
                    :
                        numberStockTotal > 0 ?
                            <div>
                                <Button label={"Exporter"} icon={<Download size={"1rem"}/>} onClick={() => handleExport()}/>
                                <p><strong>Date de dernière MAJ :</strong> {date}</p>
                                <div className={classes.orders_table_container}>
                                    <table className={classes.orders_table}>
                                        {
                                            currentFilter ?
                                                <StocksHeading state={currentFilter} handleChangeFilter={handleChangeFilter} orderBy={orderBy} getUser={getUser}/>
                                            :null
                                        }
                                        <tbody>
                                            {listStocks}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={classes.blocPagination}>
                                    <div className={classes.containerPagination}>
                                        <p className={classes.elements}>Affichage de {numberStock - itemsPerPage} à {numberStock} sur {numberStockTotal} éléments</p>
                                        <div className={classes.pagination}>
                                            <ReactPaginate
                                                previousLabel={"△"}
                                                nextLabel={"△"}
                                                breakLabel={""}
                                                breakClassName={"break-me"}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={-1}
                                                pageRangeDisplayed={-1}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                forcePage={currentPage}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.selectPagination}>
                                        <select className={classes.select} value={itemsPerPage} onChange={e => setItemsPerPage(e.target.value)}>
                                            {Object.entries(nbPage).map((e,i) => (
                                                <option key={i} value={e[1]}>{e[0]}</option>
                                            ))}
                                        </select>                            
                                        <p className={classes.selectPaginationText}>éléments par page</p>
                                    </div>
                                </div>
                            </div>
                        : 
                        <h3 className={classes.elements}>Aucun stock disponible</h3>
                }
                <div className={modaleOpen ? classes.modal : classes.modalClose}>
                    <div className={classes.backgroundModal} onClick={() => handleClose()}/>
                    <div className={classes.modalContainer}>
                        {renderModaleContent()}
                    </div>
                </div>
            </div>
        </div>
    )
}