import { useEffect, useState } from 'react';
import {fetchApi} from '../../utils/fetchApi';
import classes from './dashboard.module.css';
import Button from "../../components/Button/button";
import moment from 'moment';
import frLocale from "moment/locale/fr";
import Loader from '../../components/Loader';
import ButtonSwitch from '../../components/Button/buttonSwitch';
import Transport from './transport';
import Logistique from './logistique';

function Dashboard(props){
    props.logout(true, false);
    moment.locale('fr', [frLocale])
    const [lastUpdate, setLastUpdate] = useState(null);
    const [errorApi, setErrorApi] = useState(null);
    const [loadingLog, setLoadingLog] = useState(true);
    const [stats, setStats] = useState({});
    const [periode, setPeriode] = useState();
    const [startAt, setStartAt] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [page, setPage] = useState('transport');    

    const handeGetStats = async (startDate = false, forceRefresh = false) =>{
        setLoadingLog(true);

        var date = (startDate) ? startDate : startAt
        setStartAt(date)
        setPeriode(moment(date).locale('fr').format('MMMM YYYY'))
        
        let data = await fetchApi('stti/flat-dashboard',{startDate: date, refreshCache: forceRefresh});
        
        if (data.success === false) {
            setErrorApi(data.message)
            setLoadingLog(false)
            return
        }
        setErrorApi(null)
        setStats(data.stats)
        setLastUpdate(data.lastUpdate.date)
        setLoadingLog(false)
    }

    useEffect(() => {
        handeGetStats()        
    }, [])    

    const handleChangeDate = (event) => {
        setStartAt(event.target.value)
        setPeriode(moment(event.target.value).locale('fr').format('MMMM YYYY'))

        handeGetStats(event.target.value)
    }

    return(
        <div className={classes.root}>
            <div className={classes.containerLog}>
                <div className={classes.buttonContainer}>
                    <ButtonSwitch active={page === 'transport'} onClick={() => setPage("transport")} label={"Transport"} />
                    <ButtonSwitch active={page === 'logistique'} onClick={() => setPage("logistique")} label={"Logistique"} />
                </div>                        
                        {
                            loadingLog ?
                                <>
                                    <h1>Votre tableau de bord</h1>
                                    <Loader label="Chargement des stats" />
                                </>
                            : 
                                (
                                    errorApi ? 
                                    <>
                                        <h1>Votre dashboard</h1>
                                        <input className={classes.inputTypeDate} type="month" onChange={(e) => handleChangeDate(e)} value={startAt}/>
                                        <div style={{maxWidth: '100%'}}>
                                            <h3>{errorApi}</h3>
                                            <Button onClick={() => handeGetStats()} label={"Réessayer"} />
                                        </div>                                    
                                    </>
                                    :
                                    <>
                                        <div className={classes.containerHeader}>
                                            <h1>Votre dashboard</h1>
                                            <div style={{textAlign: 'center'}}>
                                                <p style={{marginBottom: '5px', color: 'grey'}}>Dernière MAJ: {moment(lastUpdate).format('D MMMM YYYY')}</p>
                                                <Button onClick={() =>handeGetStats(startAt, true)} label={"Recalculer les stats"} />
                                            </div>                                            
                                        </div>
                                        <div className={classes.date}>
                                            <p className={classes.dateTitle}>période</p>
                                            <input className={classes.inputTypeDate} type="month" onChange={(e) => handleChangeDate(e)} value={startAt}/>
                                        </div>
                                        <p>Période en cours: {periode}</p>
                                        {
                                            page === 'transport'&&
                                            <Transport stats={stats.TRANS}/>
                                        }
                                        {
                                            page === 'logistique'&&
                                            <Logistique stats={stats.LOG}/>
                                        }
                                        {/* <Stats stats={stats} periode={periode}/> */}
                                    </>
                                )
                        }
            </div>
        </div>
    )
}

export default Dashboard;