import classes from './item.module.css'
import moment from 'moment'
import { searchData } from '../../../utils/functions';

export default function ArticleItem(props){
    const {item} = props;
    return(
        <tr className={classes.root}>
            {/* <td>{moment(item.created_at.date).format('DD-MM-YYYY')}</td> */}
            <td>{item.codeArticle}</td>
            <td>{item.libelleArticle}</td>
            <td>{item.qte}</td>
            <td>{item.lot}</td>
        </tr>
    )
}