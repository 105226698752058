import DefaultCard from "../../components/Cards/defaultCard"
import classes from "./orderSection.module.css";
import {Collapse} from 'react-collapse';
import {useState,useMemo} from 'react'
import { ChevronDown,ChevronUp, Grid } from "react-feather";
import Address from "../../components/Adress";
import OrderArticles from "./OrderArticles";
import _ from 'lodash';



export default function OrderSection(props){
    const { identifier, datas, moreInfos } = props;
    var renderIdentifier = identifier;
    if (renderIdentifier === 'INFORMATION') renderIdentifier = 'INFORMATIONS'

    const renderInfo = (name, data) => {
        return (<>{
            data || data === 0 ?
                <li className={classes.data_item} key={`informations-data-code-activite`}>
                    <strong>{name} : </strong>
                    <span>{data}</span>
                </li>
                : null
        }</>)
    }

    return (
        <div className="section">
            <div className={classes.heading_open} >
                <h2 className={classes.title}>{renderIdentifier}</h2>
            </div> 
            <DefaultCard style={{marginTop : '1rem'}}>
                <div className={classes.content}>  
                    {identifier === 'CLIENT'
                        ?
                        <>
                            <Address
                                name={datas.clientNom}
                                address={datas.clientAdresse1}
                                city={datas.clientVille}
                                zip={datas.clientCp}
                                country={datas.clientPays}
                                tel={datas.clientTel}
                            />                        
                        </>
                        : null
                    }

                    {identifier === 'LIVRAISON'?
                    <>
                        <Address
                            name={datas.livraisonNom}
                            address={datas.livraisonAdresse1}
                            city={datas.livraisonVille}
                            zip={datas.livraisonCp}
                            country={datas.livraisonPays}
                            tel={datas.livraisonTel}
                        />     
                    </>
                    :null
                    }

                    {identifier === 'INFORMATIONS' && datas.sttiFlatInformation ?
                        <>
                            <ul className={classes.data_list}>
                                {renderInfo('CODE ACTIVITE', datas.sttiFlatInformation.infoCodeActivite)}
                                {renderInfo('NOMBRE COLIS', datas.sttiFlatInformation.infoNbColis)}

                                {renderInfo('LIBELLE ACTIVITÉ', datas.sttiFlatInformation.infoLibelleActivite)}
                                {renderInfo('NOMBRE PALETTES', datas.sttiFlatInformation.infoNbPal)}

                                {renderInfo('VOLUME', datas.sttiFlatInformation.infoVolume)}
                                {renderInfo('CONTRE-REMBOURSEMENT', datas.sttiFlatInformation.infoContreRemboursement)}

                                {renderInfo('POIDS', datas.sttiFlatInformation.infoPoids)}
                                {renderInfo('CUMUL MONTANT HT', datas.sttiFlatInformation.infoCumulMontantHt)}

                                {renderInfo('UNITÉ POIDS', datas.sttiFlatInformation.infoUnitePoids)}
                                {renderInfo('LIVRAISON OBSERVATION', datas.livraisonObservation)}
                                
                                {renderInfo('MÈTRES LINÉAIRES', datas.sttiFlatInformation.infoMl)}
                            </ul>
                        </>
                        : null
                    }

                    {identifier === 'LOGISTIQUE' && (datas.sttiFlatArticles) ? 
                        <OrderArticles datas={datas.sttiFlatArticles}/>
                    : null}

                    {identifier === 'CHARGEMENT'?
                    <>
                        <Address
                            name={datas.chargementNom}
                            address={datas.chargementAdresse1}
                            city={datas.chargementVille}
                            zip={datas.chargementCp}
                            country={datas.chargementPays}
                            tel={datas.chargementTel}
                        />     
                    </>
                    :null
                    }

                </div>
            </DefaultCard>
        </div>
    )
}