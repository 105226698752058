import { useEffect, useState } from 'react';
import logo from './logo.svg';
import moment from 'moment';
import classes from './app.module.css';
import { Routes, Route,Navigate, useNavigate, useLocation } from "react-router-dom";
import Orders from './RootComponents/Orders'
import Tracking from './RootComponents/Tracking';
import Customer from './RootComponents/Customer';
import Navbar from './components/Navbar/navbar';
import OrderDetails from './RootComponents/OrderDetails';
import Login from './RootComponents/Login';
import Stocks from './RootComponents/Stocks';
import CustomersList from './RootComponents/CustomersList';
import Dashboard from './RootComponents/Dashboard';

function App() {
  const [isLog, setIsLog] = useState(false);

  let nav = useNavigate();
  let location = useLocation();

  const logout = (redirectLogin = false, redirectHome = false) => {
    let user  = localStorage.getItem('AUTH_EMAIL');
    let expiry  = localStorage.getItem('AUTH_TOKEN_EXPIRY');
    if (!user || !expiry) {
        localStorage.removeItem('AUTH_EMAIL');
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('AUTH_TOKEN_EXPIRY');
        localStorage.removeItem('USER');
        setIsLog(false);
        if(redirectLogin){
          nav('/login');
        }
        return;
      }
      
      expiry = moment(expiry);
      
      let remaingSeconds = expiry.diff(moment(), 'seconds');
      
      if (remaingSeconds < 0){
        localStorage.removeItem('AUTH_EMAIL');
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('AUTH_TOKEN_EXPIRY');
        localStorage.removeItem('USER');
        setIsLog(false);
        if(redirectLogin){
          nav('/login');
        }
      }
      else if(redirectHome){
        setIsLog(true);
        nav('/');
    }
  };

  const unlog = () => {
    localStorage.removeItem('AUTH_EMAIL');
    localStorage.removeItem('AUTH_TOKEN');
    localStorage.removeItem('AUTH_TOKEN_EXPIRY');
    localStorage.removeItem('USER');
    setIsLog(false);
    nav('/login');
  }

  useEffect(() => {
    let user  = localStorage.getItem('AUTH_EMAIL');
    let expiry  = localStorage.getItem('AUTH_TOKEN_EXPIRY');
    if(user && expiry){
      setIsLog(true);
    }
  }, [])

  return (
    <div className="app">
        <Navbar isLog={isLog} unlog={() => unlog()} location={location} />
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard"/>}/>

          <Route path="/dashboard" element={<Dashboard logout={logout}/>}/>
          <Route path="/orders" element={<Orders logout={logout} />} />
          <Route path="/tracking" element={<Tracking logout={logout} isLog={isLog} />} />
          <Route path="/tracking/:num_cmd" element={<Tracking logout={logout} isLog={isLog} />} />
          <Route path="/customers" element={<CustomersList logout={logout} isLog={isLog}/>} />
          <Route path="/customer" element={<Customer logout={logout} />} />
          <Route path="/orders/:id" element={<OrderDetails logout={logout} />} />
          <Route path="/stocks" element={<Stocks logout={logout} />} />

          <Route path="/login" element={<Login logout={logout} log={() => setIsLog(true)}  />} />
        </Routes>
    </div>
  );
}

export default App;
