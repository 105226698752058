import classes from '../trackingStep.module.css';
import FinalStepSvg from "../../../assets/images/finalstep.svg";
import moment from 'moment';

export default function LadSma(props) {
    return(
        <>
            {
                props.statusTracking.prise_compte.status === true ?
                    <div className={classes.containerStep}>
                        <div className={classes.bar_root_first}>
                            <span className={classes.bar_first}/>
                        </div>
                        <p className={classes.description}>Prise en compte<br /> de la commande <br/>{moment(props.statusTracking.prise_compte.date).format('DD/MM/YYYY')} </p>
                    </div>
                    : 
                    <div className={classes.containerStep}>
                        <div className={classes.bar_root_first_change}>
                            <span className={classes.bar_first_change}/>
                        </div>
                        {/* <p className={classes.description}>Prise en compte<br /> de la commande</p> */}
                    </div>
            }
            
            {
                props.statusTracking.livraison.status === true && props.statusTracking.rdv.status === false && props.statusTracking.prep.status == false ?
                    null
                    :
                    props.statusTracking.rdv.status === true && props.statusTracking.prep.status == false ?
                        null
                    : 
                    props.statusTracking.prep.status == true ?
                        <div className={classes.containerStep}>
                            <div className={classes.bar_root}>
                                <span className={classes.bar}/>
                            </div>
                            <p className={classes.description}>Commande en<br/> préparation <br/>{moment(props.statusTracking.prep.date).format('DD/MM/YYYY')} </p>
                        </div>
                        :
                        <div className={classes.containerStep}>
                            <div className={classes.bar_root_first_change}>
                                <span className={classes.bar_first_change}/>
                            </div>
                            {/* <p className={classes.description}>Commande en<br/> préparation</p> */}
                        </div>
            }

            {
                // props.statusTracking.rdv.status === true?
                props.statusTracking.livraison.status === true && props.statusTracking.expedition.status === false && props.statusTracking.rdv.status === false ?
                    null
                    :
                    props.statusTracking.expedition.status == true && props.statusTracking.rdv.status === false ?
                        null
                    :
                        props.statusTracking.rdv.status === true ?
                        <div className={classes.containerStep}>
                            <div className={classes.bar_root}>
                                <span className={classes.bar}/>
                            </div>
                            <p className={classes.description}>Rdv pris pour le<br/>{moment(props.statusTracking.rdv.date).format('DD/MM/YYYY')}</p>
                        </div>
                        :
                        <div className={classes.containerStep}>
                            <div className={classes.bar_root_first_change}>
                                <span className={classes.bar_first_change}/>
                            </div>
                            {/* <p className={classes.description}>Pas encore de <br/> rdv</p> */}
                        </div>
                // : null
            }

            {
                props.statusTracking.livraison.status === true && props.statusTracking.expedition.status == false ?
                    null
                :
                props.statusTracking.expedition.status == true ?
                    <div className={classes.containerStep}>
                        <div className={classes.bar_root}>
                            <span className={classes.bar}/>
                        </div>
                        <p className={classes.description}>Commande<br/> expédiée le <br/>{moment(props.statusTracking.expedition.date).format('DD/MM/YYYY')} </p>
                    </div>
                    :
                    <div className={classes.containerStep}>
                        <div className={classes.bar_root_first_change}>
                            <span className={classes.bar_first_change}/>
                        </div>
                        {/* <p className={classes.description}>Commande<br/> pas encore expédiée</p> */}
                    </div>
            }
            
            {
                props.statusTracking.livraison.status === true ? 
                    <div className={classes.containerStep}>
                        <div className={classes.bar_root}>
                            <span className={classes.bar_last}>
                                <img className={classes.imageStep} src={FinalStepSvg} />
                            </span>
                        </div>
                        <p className={classes.description_last_step}>Commande livrée<br/>le {moment(props.statusTracking.livraison.date).format('DD/MM/YYYY')}</p>
                    </div>
                    :
                    <div className={classes.containerStep}>
                        <div className={classes.bar_root}>
                            <span className={classes.bar_last_change}>
                                <img className={classes.imageStepChange} src={FinalStepSvg} />
                            </span>
                        </div>
                        <p className={classes.description_last_step} style={{color: 'grey'}}>Commande livrée</p>
                    </div>
            }
            
            
        </>
    )
}