import { ChevronDown, ChevronUp } from 'react-feather';
import classes from './stocksHeading.module.css';

export default function StocksHeading(props) {
    return(
        <thead className={classes.root}>
            <tr>
                {
                    props.getUser?.userGroup?.code === "superadmin" ?
                        <th>
                            <p>Client</p>
                            <input
                                className={classes.input}
                                onChange={(e) => props.handleChangeFilter(e.target.value, 'clientCode')}
                                value={props.state.clientCode.input}
                            /> 
                        </th> 
                    : null
                }
                <th>
                    <p>Code article</p>
                    <input
                        className={classes.input}
                        onChange={(e) => props.handleChangeFilter(e.target.value, 'articleCode')}
                        value={props.state.articleCode.input}
                    />
                </th> 
                <th>
                    <p>Réservation</p>
                    <select className={classes.input} onChange={(e) => props.handleChangeFilter(e.target.value, 'reservation')} value={props.state.reservation.input}>
                        <option selected={props.state.reservation.input === false} value={false}>Non</option>
                        <option selected={props.state.reservation.input === '1'} value="1">Oui</option>
                    </select>
                </th>
                <th>
                    <p>Préparation</p>
                </th> 
                <th>
                    <p>Disponible</p>
                </th>
                <th>
                    <p>Rupture</p>
                    <select className={classes.input} onChange={(e) => props.handleChangeFilter(e.target.value, 'rupture')} value={props.state.rupture.input}>
                        <option selected={props.state.rupture.input === false} value={false}>Non</option>
                        <option selected={props.state.rupture.input === '1'} value="1">Oui</option>
                    </select>
                </th>
                <th>
                    <div className={classes.containerIcon}>
                        <p>Stock total</p>
                    </div>
                </th>
                <th style={{maxWidth: 40}}>
                    <div className={classes.containerIcon}>
                        <div className={classes.icon} onClick={() => props.handleChangeFilter(props.orderBy === "asc" ? "desc" : "asc", "orderBy")}>
                            {props.orderBy}
                            {
                                props.orderBy === "asc" ?
                                <ChevronUp />
                                : 
                                <ChevronDown />
                            }
                        </div>
                    </div>
                </th>
            </tr>
        </thead>
    )
}