import classes from './button.module.css';


export default function Button(props){
    const {
        label,
        onClick = ()=>{},
        variant = "default",
        icon,
        disabled,
        ...restProps
    } = props;

    const buttonClass = disabled ? classes.disabled : `${classes[`${variant}`]}${icon ? ` ${classes.with_icon}` : ''}`;
    return(
        <button className={buttonClass} onClick={onClick} {...restProps}>
            {icon ?? null}
            {label}
        </button>
    )
}