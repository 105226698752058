import { searchData } from "../../../utils/functions";
import { Link } from "react-router-dom";
import classes from "./stockItem.module.css";
import Button from "../../../components/Button/button";
import {Send, Eye, CornerDownLeft, Info } from "react-feather";
import Adress from "../../../components/Adress";
import details from '../../../assets/images/details.svg'
import pod from '../../../assets/images/pod.svg'

import moment from 'moment'
export default function StockItem(props){
    const {stock, state} = props;
    const { children } = stock;
    const date = moment(stock.updatedAt.date).format('DD/MM/YYYY, h:mm:ss');
    return(
        <div className={classes.containerStockItem}>
            {
                stock.stockTotal == 0 ?(
                    <tr className={classes.rootRed}>
                        {
                            state.clientCode.visibility == true ?
                                <td>{stock.clientCode}</td>
                            : null
                        }
                        <td>{stock.articleCode}</td> 
                        <td onClick={stock.reservation > 0 ? () => props.handleClick(stock, 'reservation') : null}><div className={classes.containerIcon} style={{cursor: stock.reservation > 0 ? 'pointer' : 'inherit'}}>{stock.reservation}{stock.reservation > 0 ? <Info size="1.5rem"/> : null}</div></td> 
                        <td>{stock.preparation}</td> 
                        <td>{stock.disponible}</td> 
                        <td onClick={stock.rupture > 0 ? () => props.handleClick(stock, 'rupture') : null}><div className={classes.containerIcon} style={{cursor: stock.rupture > 0 ? 'pointer' : 'inherit'}}>{stock.rupture}{stock.rupture > 0 ? <Info size="1.5rem"/> : null}</div></td> 
                        <td>{stock.stockTotal}</td> 
                        <td></td>
                    </tr>
                ) : (
                    <tr className={classes.rootGreen}>
                        {
                            state.clientCode.visibility == true ?
                                <td>{stock.clientCode}</td>
                            : null
                        }
                        <td>{stock.articleCode}</td> 
                        <td onClick={stock.reservation > 0 ? () => props.handleClick(stock, 'reservation') : null}><div className={classes.containerIcon} style={{cursor: stock.reservation > 0 ? 'pointer' : 'inherit'}}>{stock.reservation}{stock.reservation > 0 ? <Info size="1.5rem"/> : null}</div></td> 
                        <td>{stock.preparation}</td> 
                        <td>{stock.disponible}</td> 
                        <td onClick={stock.rupture > 0 ? () => props.handleClick(stock, 'rupture') : null}><div className={classes.containerIcon} style={{cursor: stock.rupture > 0 ? 'pointer' : 'inherit'}}>{stock.rupture}{stock.rupture > 0 ? <Info size="1.5rem"/> : null}</div></td> 
                        <td>{stock.stockTotal}</td> 
                        <td></td>
                    </tr>
                )
            }
            
        </div>
    )
}
