import { useMemo, useState, useEffect } from 'react';
import { fetchResearch, getActivityList } from "../../utils/fetchApi"
import classes from './orders.module.css'
import { prepareFilters, prepareSearch } from '../../utils/functions';
import OrderItem from './OrderItem';
import Loader from '../../components/Loader';
import OrdersHeading from './OrdersHeading/ordersHeading';
import ReactPaginate from 'react-paginate';
import PictoFilterBlue from '../../assets/images/filterBlue.svg';
import Popup from './popupFilter';
import _ from 'lodash';
import moment from 'moment';



export default function Orders(props) {
    let getUser = JSON.parse(localStorage.getItem('USER'));
    const [orders, setOrders] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchVar, setSearchVar] = useState({});
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const nbPage = {
        10: 10,
        20: 20,
        30: 30,
    }
    const [startAt, setStartAt] = useState(JSON.parse(localStorage.getItem('startDate')) || moment().startOf('month').format('YYYY-MM-DD'));
    const [endAt, setEndAt] = useState(JSON.parse(localStorage.getItem('endDate')) ||null);
    const [isOpen, setIsOpen] = useState(false);
    const [isFilterInput, setIsFilterInput] = useState(false);
    const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('Filter')) || {
        CLIENT_CODE: { visibility: getUser?.userGroup.code === "superadmin" ? true : false , input: getUser?.userGroup.code === "superadmin" ? null : getUser?.userGroup.code},
        QUAI: { visibility: true, input: null },
        NUM_DOSSIER_TRANS: { visibility: true, input: null },
        DATE_CREATION_TRANS: { visibility: true, input: null },
        NUM_CMD_CLIENT: { visibility: true, input: null },
        LIVRAISON_NOM: { visibility: true, input: null },
        LIVRAISON_CP: { visibility: true, input: null },
        LIVRAISON_DATEPREVUE: { visibility: true, input: null },
        LIVRAISON_DATEREELLE: { visibility: true, input: null },
        statut: { visibility: true, input: null },
        pod: { visibility: true, input: null },
        NB_COLIS: { visibility: false, input: null },
        POIDS: { visibility: false, input: null },
        VOLUME: { visibility: false, input: null },
        NB_PAL: { visibility: false, input: null },
    });
    const [currentFilter, setCurrentFilter] = useState(null);
    const [numberOrder, setNumberOrder] = useState(null);
    const [numberOrderTotal, setNumberOrderTotal] = useState(null);
    const [activityList, setActivityList] = useState([]);

    


    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const handleCheck = (e, type) => {
        setIsFilterInput(false)
        let getFilter = { ...filter };
        getFilter[type].visibility = !getFilter[type].visibility;
        setFilter(getFilter);
    }

    const handleChangeFilter = (e, type) => {
        setIsFilterInput(true)
        let getFilters = { ...filter };
        getFilters[type].input = e;
        setFilter(getFilters);
        setCurrentFilter(_.cloneDeep(getFilters));
    }

    const getOrders = async (pageCurrent) => {
        //TODO DYNAMIC PARENTS VARIABLE
        let arrayParents = [];

        if (localStorage.getItem('AUTH_EMAIL') === 'ccp@gmail.com') {
            arrayParents.push({ "eav_type": "CLIENT", "shellDatas": [{ "identifier": "client_code", "value": "CCP" }] });
        }
        else if (localStorage.getItem('AUTH_EMAIL') === 'cote@gmail.com') {
            arrayParents.push({ "eav_type": "CLIENT", "shellDatas": [{ "identifier": "client_code", "value": "COTE" }] });
        }

        let variables = {
            "itemsPerPage": itemsPerPage,
            "page": pageCurrent + 1 || currentPage + 1,
            "parents": arrayParents,
        };
        if (startAt && startAt !== ""){
            variables['startDate'] = startAt
        }
        if (endAt && endAt !== ""){
            variables['endDate'] = endAt
        }
        variables['shellDatas'] = prepareSearch(searchVar)
        variables['shellDatas'] = prepareFilters(filter)

        setLoading(true);

        let orders = await fetchResearch(variables);

        let ordersActivityList = await getActivityList()

        setActivityList(ordersActivityList)

        setNumberOrderTotal(orders.paginationInfo.totalCount);
        setNumberOrder((pageCurrent + 1 || currentPage + 1) * itemsPerPage);
        setOrders(orders?.collection);
        // setItemsPerPage(orders.paginationInfo.itemsPerPage);
        setTotalCount(orders.paginationInfo.totalCount);
        setLastPage(orders.paginationInfo.lastPage);

        setLoading(false);
    }

    const handleSearch = (e) => {
        e?.preventDefault();
        setSearchVar({ ...searchVar, "NUM_CMD_CLIENT": e.target.search.value })
    }

    const listOrders = useMemo(() => {
        return (
            orders?.map((e, index) => {
                return (
                    <OrderItem order={e} key={`order-${index}`} state={currentFilter}/>
                )
            })
        )
    }, [orders, currentFilter]);

    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        getOrders(selectedPage);
    };
    const handleApply = () => {
        setIsOpen(false)
        let getFilters = {...filter}
        setCurrentFilter(_.cloneDeep(getFilters))
    };

    useEffect(() => {
        getOrders();
    }, [searchVar, itemsPerPage, startAt, endAt]);

    useEffect(() => {
        getOrders();
    }, [currentFilter?.QUAI.input]);

    useEffect(() => {
        // if (isFilterInput) {
        //     const timeout = setTimeout(() => {
        //         getOrders()
        //     }, 2000)
        //     return () => clearTimeout(timeout);
        // }
        localStorage.setItem('Filter', JSON.stringify(filter));

    }, [filter, currentFilter, startAt, endAt]);  
    
    useEffect(() => {
        localStorage.setItem('startDate', JSON.stringify(startAt));        
    }, [startAt]);  

    useEffect(() => {        
        localStorage.setItem('endDate', JSON.stringify(endAt));
    }, [endAt]);  

    useEffect(() => {
        props.logout(true, false);
        let getFilters = {...filter}
        if (getUser?.userGroup.code !== "superadmin"){
            getFilters['CLIENT_CODE'].visibility = false;
            getFilters['CLIENT_CODE'].input = getUser?.userGroup.code;
            setFilter(getFilters);
        }
        setCurrentFilter(_.cloneDeep(getFilters))
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getOrders()
        }
    }
    const handleChangeDate = (event, type) => {
        if (type === 'start'){
            setStartAt(event.target.value)
        }else{
            setEndAt(event.target.value)
        }
    }

    const resetFilter = ()=>{
        let reset = {...filter}        
        Object.keys(reset).map((item)=>{
            reset[item].input = null            
        })
        try {
            setFilter(reset)
            setCurrentFilter(reset)                
            setStartAt(moment().startOf('month').format('YYYY-MM-DD'))
            setEndAt(null)     
        }finally{
            getOrders()
        }    
    }        
        
    const IS_DEV = false;    
    return (
        <div className={classes.root}>
            {isOpen && <Popup
                handleClose={() => { setIsOpen(!isOpen) }}
                handleApply={() => handleApply()}
                state={filter}
                handleCheck={handleCheck}
                getUser={getUser}
            />}
            {/* <p class="right">Commandes</p> */}
            <div className={classes.periodSearch}>
                <p className={classes.leftTitle}>Période de recherche</p>
                <div className={classes.inputsDates}>
                    <div className={classes.inputContainer}>
                        <div className={classes.inputDate}>
                            <p className={classes.dateTitle}>Date de début</p>
                            <input className={classes.inputTypeDate} type="date" onChange={(e) => handleChangeDate(e, 'start')} value={startAt}/>
                        </div>
                        <div className={classes.inputDateBis}>
                            <p className={classes.dateTitle}>Date de fin</p>
                            <input className={classes.inputTypeDate} type="date" onChange={(e) => handleChangeDate(e, 'end')} value={endAt?endAt:'aaaa-mm-dd'} min={startAt} />
                        </div>
                    </div>
                    <div className={classes.filterContainer}>
                        <div className={classes.filter}>
                            <p className={classes.leftTitleFilter}>Filtres</p>
                            <img className={classes.pictoFilter} src={PictoFilterBlue} onClick={togglePopup}/>
                        </div>
                        <div className={classes.search}>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <button onClick={resetFilter}>Réinitialiser les filtres</button>
            </div>

            {/* <TopPanel
                title="Commandes"
                description="Gestion des commandes"
                searchHandler={handleSearch}
                searchPlaceholder='Rechercher une commande par NUM_CMD_CLIENT'
            /> */}

            
            {/* <div className={classes.formSearch}>
                <form
                   //onSubmit={onSubmit}
                   //className={classes.root}
                >
                    <ButtonBis
                        type="submit"
                        label="Recherche"
                        //icon={<Search size="1rem" />}
                    />
                    <input
                        // placeholder={placeholder}
                        // name="search"
                        // className={classes.input}
                        // value={value}
                        // onChange={handleChange}
                        //onChange={(e) =>handleChangeFilter(e.target.value, 'CLIENT_CODE') }
                        //value={filter.CLIENT_CODE.input}
                    />
                </form>
            </div> */}


{/* 
            {!IS_DEV ?
                <div style={{ marginTop: '2rem' }}>
                    <AdvancedSearch config={advancedSearchConfig} searchHandler={handleSearch} setOrders={setOrders}
                        setLoading={setLoading} setTotalCount={setTotalCount}
                        setLastPage={setLastPage} setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage} />
                </div>

                : null
            } */}

            <div className={classes.orders_container}>
                {loading ?
                    <Loader label="chargement en cours" />
                    :
                    <div>
                        <div className={classes.orders_table_container}>
                            <table className={classes.orders_table}>
                                {
                                    currentFilter ?
                                        <OrdersHeading state={currentFilter} handleChangeFilter={handleChangeFilter} handleKeyDown={handleKeyDown} activityList={activityList}/>
                                    : null
                                }
                                <tbody>
                                    {listOrders}
                                </tbody>
                            </table>
                        </div>
                        <div className={classes.blocPagination}>
                            <div className={classes.containerPagination}>
                                <p className={classes.elements}>Affichage de {numberOrder - itemsPerPage} à {numberOrder} sur {numberOrderTotal} éléments</p>
                            <div className={classes.pagination}>
                                <ReactPaginate
                                    previousLabel={"△"}
                                    nextLabel={"△"}
                                    breakLabel={""}
                                    breakClassName={"break-me"}
                                    pageCount={lastPage}
                                    marginPagesDisplayed={-1}
                                    pageRangeDisplayed={-1}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage}
                                />
                            </div>
                            </div>
                            <div className={classes.selectPagination}>
                                <select className={classes.select} value={itemsPerPage} onChange={e => setItemsPerPage(e.target.value)}>
                                    {Object.entries(nbPage).map((e,i) => (
                                        <option key={i} value={e[1]}>{e[0]}</option>
                                    ))}
                                </select>                            
                                <p className={classes.selectPaginationText}>éléments par page</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}