import { Link } from "react-router-dom";
import classes from "./orderItem.module.css";
import Adress from "../../../components/Adress";
import details from '../../../assets/images/details.svg';
import pod from '../../../assets/images/pod.svg';
import picto1 from '../../../assets/images/pictos_statut/picto_1.png';
import picto2 from '../../../assets/images/pictos_statut/picto_2.png';
import picto3 from '../../../assets/images/pictos_statut/picto_3.png';
import picto4 from '../../../assets/images/pictos_statut/picto_4.png';
import picto5 from '../../../assets/images/pictos_statut/picto_5.png';
import picto6 from '../../../assets/images/pictos_statut/picto_6.png';
import picto7 from '../../../assets/images/pictos_statut/picto_7.png';
import picto8 from '../../../assets/images/pictos_statut/picto_8.png';
import picto9 from '../../../assets/images/pictos_statut/picto_9.png';
import moment from 'moment';
import { useNavigate } from "react-router-dom";

export default function OrderItem(props){
    const {order} = props;
    const {children} = order;
    const id = order?.refClient;
    let type = {type: order?.typeEvt, code: order?.codeEvt};
    let date = order?.createdAt;
    const CLIENT = order?.clientCode;
    // const LIVRAISON = children.find(e => e.eav_type === 'LIVRAISON'); 
    const DESTINATAIRE = order?.destinataire;
    const ADDRESS = (order?.livraisonCp ?? '') + ' ' + (order?.livraisonVille ?? '') + ' ' + (order?.livraisonPays ?? '');
    const CODEACTIVITE = order?.codeActivite;
    const VOLUME = order?.volume;
    const PALETTES = order?.palette;
    const POIDS = order?.poids;
    const UNITEPOIDS = order?.unitePoids;
    const COLIS = order?.colis;
    const POD = order?.pod ;
    const DATE_REELLE = order?.dateReelleRdv;
    moment.updateLocale(moment.locale(), { invalidDate: "N/A" });
    const DATE_RDV = order?.dateRdv;
    const NUM_DOSSIER = order?.numDossier;

    const renderPicto = () => {
        if (type?.type === "LOG"){
            switch(type.code){
                case 'CRE':
                    return <img src={picto1} className={classes.pictoDetails} alt="Prise en compte Logistique" title="Prise en compte Logistique"/>
                case 'RES':
                    return <img src={picto2} className={classes.pictoDetails} alt="Marchandises réservées" title="Marchandises réservées"/>
                case 'RU1':
                    return <img src={picto3} className={classes.pictoDetails} alt="Rupture Partielle" title="Rupture Partielle"/>
                case 'RU2':
                    return <img src={picto4} className={classes.pictoDetails} alt="Rupture Totale" title="Rupture Totale"/>
                case 'PRE':
                    return <img src={picto7} className={classes.pictoDetails} alt="Préparation Commande" title="Préparation Commande"/>
                case 'VAL':
                    return <img src={picto8} className={classes.pictoDetails} alt="Commande prête pour Expédition" title="Commande prête pour Expédition"/>
                case 'LIV':
                    return <img src={picto9} className={classes.pictoDetails} alt="Commande livrée" title="Commande livrée"/>
                default:
                    return <img src={picto1} className={classes.pictoDetails} alt="Prise en compte Logistique" title="Prise en compte Logistique"/>
            }
        }else if (type?.type === "TRANS"){
            console.log(type);
            switch(type.code){
                case 'CRE':
                    return <img src={picto5} className={classes.pictoDetails} alt="Prise en compte Transport" title="Prise en compte Transport"/>
                case 'DIF':
                    return <img src={picto6} className={classes.pictoDetails} alt="Rendez-vous pris" title="Rendez-vous pris"/>
                case 'LIV':
                    return <img src={picto9} className={classes.pictoDetails} alt="Commande livrée" title="Commande livrée"/>
                default:
                    return <img src={picto5} className={classes.pictoDetails} alt="Prise en compte Transport" title="Prise en compte Transport"/>
            }
        }
    }

    const nav = useNavigate()

    const dobleClick =(e)=>{
        if(e.detail === 2){
            nav(`/orders/${id.replace('/', '_')}`)
        }        
    }
    return (
        <div className={classes.lien}>
            <tr className={classes.root} onClick={dobleClick}>
                {props.state.CLIENT_CODE.visibility == true ?
                    <td>
                        {CLIENT ?
                            <Adress
                                name={CLIENT}
                                style={{ color: 'black' }}
                            />
                            
                            : '-'
                        }
                            
                    </td> : null}
                {props.state.QUAI.visibility == true ? <td>{CODEACTIVITE}</td> : null}
                {props.state.NUM_DOSSIER_TRANS.visibility == true ? <td>{NUM_DOSSIER}</td> : null}
                {props.state.DATE_CREATION_TRANS.visibility == true ? <td className={classes.date}>{date ? moment(new Date(date)).format('DD-MM-Y') : ''}</td> : null}
                {props.state.NUM_CMD_CLIENT.visibility == true ?
                    <td>
                        {id}
                    </td> : null}
                {props.state.LIVRAISON_NOM.visibility == true ?
                    <td>
                        <Adress
                            name={DESTINATAIRE}
                            style={{ color: 'black' }}
                        />
                    </td> : null}
                {props.state.LIVRAISON_CP.visibility == true ?
                    <td>
                        {ADDRESS}
                    </td> : null}
                {props.state.LIVRAISON_DATEPREVUE.visibility == true ? <td>{DATE_RDV ? moment(new Date(DATE_RDV)).format('DD-MM-Y à HH:mm') : ''}</td> : null}
                {props.state.LIVRAISON_DATEREELLE.visibility == true ?
                    <td>
                        {DATE_REELLE ? moment(new Date(DATE_REELLE)).format('DD-MM-Y à HH:mm') : ''}
                    </td> : null
                }
                {props.state.statut.visibility == true ? 
                    <td>
                        {renderPicto()}
                    </td> 
                    : null
                }
                {props.state.NB_COLIS.visibility == true ? <td>{COLIS}</td> : null}
                {props.state.POIDS.visibility == true ? <td>{POIDS} {UNITEPOIDS ? UNITEPOIDS : 'kg'}</td> : null}
                {props.state.VOLUME.visibility == true ? <td>{VOLUME}</td> : null}
                {props.state.NB_PAL.visibility == true ? <td>{PALETTES}</td> : null}
                {props.state.pod.visibility == true && POD != null ?
                    <td className={classes.actions}>
                        {/* <Link to={`${searchData("stti-URL_POD-identifier", order)}`} title="Voir le tracking">
                            <Button icon={<Send/>} variant="iconBtn" />
                            <img src={pod} className={classes.pictoDetails} />
                        </Link> */}
                        <a href={POD} target="_blank">
                            <img src={pod} className={classes.pictoDetails} />
                        </a>
                    </td> : 
                    <td className={classes.actionsNull}>
                        -
                    </td>
                    }
                {/* <td className={classes.actions}>
                    <Link to={`/orders/${id.replace('/', '_')}`} title="Voir le détail"> */}
                        {/* <Button icon={<Eye />} variant="iconBtn" /> */}
                        {/* <img src={details} className={classes.pictoDetails} />
                    </Link>                 
                </td> */}
            </tr>
        </div>
    )
}
