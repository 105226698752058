import DefaultCard from "../../components/Cards/defaultCard";
import classes from './orders.module.css'
import PictoFilterYellow from '../../assets/images/filterYellow.svg';

const Popup = props => {
        return (
            <>
            <div className={classes.backgroundContainer} onClick={props.handleClose}></div>
            <div className={classes.popup}>
                <div className={classes.heading_open}>
                    <h2 className={classes.title}>Filtres</h2>
                    <img className={classes.pictoFilter} src={PictoFilterYellow} />
                </div>
                <div className={classes.card}>
                    <DefaultCard>
                        <p className={classes.show}><a onClick={props.handleClose}>Masquer</a></p>
                        <div className={classes.containerCheckbox}>
                            {
                                props.getUser?.userGroup.code === "superadmin" ?
                                    <label className={classes.container}>Client
                                        <input
                                            type="checkbox"
                                            defaultChecked={props.state.CLIENT_CODE.visibility}
                                            onChange={(e) => props.handleCheck(e.target.value, 'CLIENT_CODE')}
                                        />
                                        <span className={classes.checkmarks}></span>
                                    </label>
                                : null
                            }
                            
                            <label className={classes.container}>Date rdv
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.LIVRAISON_DATEPREVUE.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'LIVRAISON_DATEPREVUE')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Code activité
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.QUAI.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'QUAI')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Date liv
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.LIVRAISON_DATEREELLE.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'LIVRAISON_DATEREELLE')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>N° dossier
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.NUM_DOSSIER_TRANS.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'NUM_DOSSIER_TRANS')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>POD
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.pod.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'pod')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Date création
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.DATE_CREATION_TRANS.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'DATE_CREATION_TRANS')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Colis
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.NB_COLIS.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'NB_COLIS')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Référence client
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.NUM_CMD_CLIENT.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'NUM_CMD_CLIENT')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Poids
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.POIDS.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'POIDS')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Destinataire
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.LIVRAISON_NOM.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'LIVRAISON_NOM')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Volume
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.VOLUME.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'VOLUME')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            <label className={classes.container}>Adresse
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.LIVRAISON_CP.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'LIVRAISON_CP')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                            {/* <label className={classes.container}>Statut
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.statut.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'statut')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label> */}
                            <label className={classes.container}>Palettes
                                <input
                                    type="checkbox"
                                    defaultChecked={props.state.NB_PAL.visibility}
                                    onChange={(e) => props.handleCheck(e.target.value, 'NB_PAL')}
                                />
                                <span className={classes.checkmarks}></span>
                            </label>
                        </div>
                        <div className={classes.containerButton}>
                            <button className={classes.buttonBlue} onClick={props.handleClose}><span className={classes.linkButtonBlue}>Annuler</span></button>
                            <button className={classes.buttonYellow} onClick={props.handleApply}><span className={classes.linkButtonYellow}>Appliquer</span></button>
                        </div>
                    </DefaultCard>
                </div>
            </div>
            </>
        )
    }

export default Popup;